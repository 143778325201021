import { useState, useEffect } from "react";
import { getAnalyticsData } from "services";
import { useNavigate } from "react-router-dom";
import { getDateRange, formatPercentage } from "utils";
const useVideoPerformanceStats = () => {
  const navigate = useNavigate();
  const [period, setPeriod] = useState("1-jahr");
  const [loading, setLoading] = useState(true);
  const [stats, setStats] = useState({
    current: [],
    previous: [],
  });
  const changeFilter = (value) => {
    const finalValue = value === "Branche wählen" ? "1-monat" : value;
    setPeriod(finalValue);
    getAnalytics(finalValue);
  };
  useEffect(() => {
    getAnalytics(period);
  }, []);

  const getAnalytics = (period) => {
    setLoading(true);
    const { fromDate, toDate, previousFromDate } = getDateRange(period);

    // Call API for the current period
    getAnalyticsData({ fromDate, toDate, groupBy: ["videoId"] })
      .then((currentResult) => {
        if (currentResult && currentResult.length > 0) {
          setStats((prevStats) => ({ ...prevStats, current: currentResult }));
        }
        // Call API for the previous period
        return getAnalyticsData({
          fromDate: previousFromDate,
          toDate: fromDate,
          groupBy: ["videoId"],
        });
      })
      .then((previousResult) => {
        if (previousResult && previousResult.length > 0) {
          setStats((prevStats) => ({
            ...prevStats,
            previous: previousResult,
          }));
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching analytics:", error);
        setLoading(false);
      });
  };

  const calculatePercentageChange = (key, videoId) => {
    // Find the current and previous data for the given videoId
    const currentData = stats.current.find((x) => x.videoId === videoId);
    const previousData = stats.previous.find((x) => x.videoId === videoId);

    // Safely access the values or default to 0 if not found
    const currentValue = currentData ? currentData[key] : 0;
    const previousValue = previousData ? previousData[key] : 0;

    let status = "increased";
    let percentage;

    if (previousValue === 0 && currentValue === 0) {
      percentage = 0;
    } else {
      let tempPreviosValue = previousValue;
      let tempAddition = 0;

      if (previousValue === 0) {
        tempPreviosValue = 1; // Prevent division by zero
        tempAddition = 1; // Adjust currentValue for percentage calculation
      }

      percentage = Math.round(
        ((currentValue + tempAddition - tempPreviosValue) / tempPreviosValue) *
          100
      );

      if (currentValue < tempPreviosValue) status = "decreased";
    }

    // Format percentage
    const formattedPercentage = formatPercentage(percentage);

    const payload = {
      percentage: formattedPercentage,
      status,
      color: status === "increased" ? "green" : "red",
    };

    return payload;
  };

  return {
    period,
    changeFilter,
    stats,
    loading,
    calculatePercentageChange,
    navigate,
  };
};
export default useVideoPerformanceStats;
