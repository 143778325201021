import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getJobDetails, getVideos } from "services";
// getProducts,
import { useDispatch } from "react-redux";
import { addVideos, addDetails } from "store/reducers/mobilePreviewSlice";

const useDashboardView = (profileId) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    getJobDetails({ params: { profileId } }).then((result) => {
      if (result) {
        dispatch(addDetails(result));
      } else {
      }
    });
    getVideos({ company: profileId, provideAddresses: true }).then((result) => {
      if (result) {
        dispatch(addVideos(result));
      } else {
      }
    });
  }, []);
  return { navigate };
};

export default useDashboardView;
